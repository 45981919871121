import { Icon } from "@iconify/react";
import { capitalize, daysSince, formatDate } from "../../common/utils";

export const BankConnectionList = ({ bankConnections }) => {
  const icons = {
    ACTIVE: "eva:checkmark-circle-outline",
    EXPIRING: "mingcute:alert-line",
    EXPIRED: "eva:alert-circle-outline",
  };

  const iconColors = {
    ACTIVE: "#450B70",
    EXPIRING: "#fd884d",
    EXPIRED: "#dc2626",
  };

  if (bankConnections.length === 0) return null;

  return (
    <ul className="border-t">
      {bankConnections?.map((account, index) => (
        <li
          key={`${account.name}+${index}`}
          className="grid grid-cols-[40px_minmax(160px,_1fr)_40px] gap-3 border-b border-float-grey-10 last:border-none px-6 py-3"
        >
          <div className="bg-float-purple-6 px-2 rounded-md h-10 flex flex-wrap content-center">
            <Icon icon="circum:bank" width={24} color="#450B70" />
          </div>
          <div className="grow my-auto">
            <p className="text-float-purple-2 font-medium text-sm leading-6 truncate">
              {account.alias ? <span>{account.alias}</span> : "Account"}
              {account.account_number && <span> {account.account_number}</span>}
              {account.iban && <span> / {account.iban}</span>}
              {account.currency && <span> ({account.currency})</span>}
            </p>
            <div className="flex">
              {account.created_at && (
                <Icon icon="formkit:date" width={12} className="my-auto" />
              )}
              <p className="text-xs font-normal float-black-90 leading-5 flex-co ml-1">
                {account.created_at
                  ? `${formatDate(account.created_at)} (${daysSince(
                      account.created_at
                    )} days ago)`
                  : null}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <div className="mt-2">
              {account.status && (
                <div className="tooltip">
                  <span className="text-xsmall mt-4">
                    {capitalize(account.status)}
                  </span>
                  <Icon
                    icon={icons[account.status]}
                    width={24}
                    color={iconColors[account.status]}
                  />
                </div>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
