import { useEffect, useState } from "react";
import { InfoModal } from "../infoModal";
import Loading from "../loading";
import { getLoanInvoices } from "../../api/invoice";
import InvoiceList from "../invoiceList";

export const PaymentsModal = ({ hide, visible, loanId }) => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (visible && loanId) {
        setLoading(true);
        const result = await getLoanInvoices(loanId);
        setPayments(result);
        setLoading(false);
      }
    };
    fetchData();
  }, [visible, loanId, setLoading]);

  if (loading) {
    return <Loading />;
  }
  return (
    <InfoModal
      hide={hide}
      visible={visible}
      title="Payments"
      closeText="Close"
      content={<InvoiceList invoices={payments} />}
    />
  );
};
