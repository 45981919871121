import { useContext } from "react";
import { UserContext } from "../../hooks/useWhoAmI";
import useAPI from "../../hooks/useAPI";
import Loading from "../loading";
import InvoiceList from "../invoiceList";

const Invoice = () => {
  const { me, loading: user_loading } = useContext(UserContext);
  const [result, loading, error] = useAPI({
    me,
    path: "v1/invoice/",
  });
  if (loading || user_loading) {
    return <Loading />;
  }

  if (error) {
    return <p>An error occurred, please try again later.</p>;
  }

  return <InvoiceList invoices={result} />;
};

export default Invoice;
