import { doDownload, doGet } from "../common/axiosUtil";

export const downloadInvoice = async (loan_reference, invoice_id) => {
  // const get = await doGet(
  //   `v1/loan/${loan_reference}/invoice/${invoice_id}/download/`,
  //   undefined,
  //   { responseType: "blob" }
  // );
  const get = await doDownload(
    `v1/loan/${loan_reference}/invoice/${invoice_id}/download/`
  );
  return get;
};

export const getLoanInvoices = async (loan_id) => {
  const get = await doGet(`v1/invoice/?loan_id=${loan_id}`);
  return get;
};
